import React, { useEffect, useState } from "react";

import Layout from './Layout'
import SEO from './SEO'

import ToastManager from "../lib/toastManager"
import UserManager from "../lib/userManager"

import { useRouter } from 'next/router'

export default function ErrorView({statusCode, type}) {
  const [refreshed, setRefreshed] = useState(false)
  const router = useRouter()
  const code = statusCode || 500;
  const message = (function(code) { 
    switch(code) {
      case 404:
        return { title: "It's looks like you're lost!", subtitle: `The ${type || "page"} you're looking for can't be found.` };
      case 429:
        return { title: "You're making too many requests!", subtitle: "Please slow down your usage of the Ladder application." }
      case 401:
        return { title: "You must login to access this page!", subtitle: "Please navigate to the login page for access."}
      case 403:
        return { title: "You can't access this page!", subtitle: "You are not authorized to view this page."}
      default:
        return { title: "We can't access the Ladder server!", subtitle: "Please try again later."}
    }
  })(code);

  useEffect(() => {
    if(code === 401){
      UserManager.logout();
      ToastManager.loginRequiredMessage();
      router.push("/login")
    }
  })

  if(code === 401) return(<div/>)

  return(
    <Layout height={360} user={UserManager.buildUser()}>
      <SEO title={code + " - " + message.title}/>
      <h1 style={{color: "white", fontWeight: "bold", textAlign: "center", fontSize: "2.3rem", marginTop: 20}}>{code} - {message.title}</h1>
      <p style={{color: "white", textAlign: "center", fontSize: "1.1rem", marginTop: 0}}>{message.subtitle}</p>
      <div style={{textAlign: "center", marginTop: 0}}>
        <button style={{textAlign: "center", margin: "0 auto"}} className={"btn btn-primary"} onClick={() => router.push('/jobs')}>View Jobs</button>
      </div>
      <div style={{textAlign: "center"}}>
        <img style={{maxWidth: "380px", marginTop: 80, marginLeft: -11}} src={`/${code}.svg`}/>
      </div>
    </Layout>
  )
}
