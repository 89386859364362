import { openPopupWidget } from "react-calendly"
import ErrorView from "../components/ErrorView"

import DomainHandler from "../lib/domainHandler"

import Layout from "../components/Layout"
import Head from "next/head"
import SEO from "../components/SEO"

import styles from "../blog/blog.module.css"

import { WPPagesAPI } from "../lib/ladder"
import WPParser from "../lib/parsePage"
import WPPageContent from "../components/WPPageContent"
import parse from 'html-react-parser'

export async function getServerSideProps(context) {
  DomainHandler.redirectToRoot(context);
  const data = await WPPagesAPI.blog()

  const blogPage = new WPParser(data.text)

  const pageBody = blogPage.body
  const pageHead = blogPage.head
  const pageTitle = blogPage.title
  const bodyClass = blogPage.bodyClass

  return {
    props: { data, pageTitle, pageHead, pageBody, bodyClass },
  }
}

export default function Blog({data, pageTitle, pageHead, pageBody, bodyClass}) {
  if(!data.success) return(<ErrorView statusCode={data.statusCode}/>)

  const description = "Explore construction HR trends, best practices, and Ladder updates";

  return(
    <Layout height={320} WP shortnav classList={bodyClass}>
      <Head>
        {parse(pageHead)}
      </Head>
      <style jsx> {`
            body {
            }
            .navbar {
              margin-bottom: 0 !important;
            }
          `}</style>
      <SEO title={pageTitle} override={true} description={description} publicVersion={true}/>
      <div className="wp-content">
        <WPPageContent
          content={pageBody}
        />
      </div>
      <BookDemo/>
    </Layout>
  )
}

function BookDemo({}) {
  return(
    <div className={styles['demo-container'] + " text-center"}>
      <style jsx>{`
        {
          color: #ffffff;
        }  
      `}</style>
      <h2>Want to hire 2x the workers in 1/2 the time?</h2>
      <p>Click on the button below to book a demo today</p>
      <button className={"btn btn-primary " + styles["plan-cta"]} onClick={() => openPopupWidget({url: 'https://calendly.com/meetladder/ladder-demo'})}>Book Demo</button>
    </div>
  )
}